import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/layout";

// import ogimage from "../assets/Images/ogImages/og-digiryte.png";
import { Card, Grid } from "@material-ui/core";
import { InlineWidget } from "react-calendly";

import CtaCardSection from "../components/CTASection/ctaCard2";
import FormSection from "../components/FormSection";
import TrustedPartner from "../components/trustedPartner";
import CTAIMAGE2 from "../assets/Images/Clutch/Cta/phone-contact.png";

import LPFooter from "../components/LPFooter";

import PlainCard from "../components/SixPlainCard";
import hoverCardImg1 from "../assets/Images/DataEngineering/card/1.svg";
import hoverCardImg2 from "../assets/Images/DataEngineering/card/2.svg";
import hoverCardImg3 from "../assets/Images/DataEngineering/card/3.svg";
import hoverCardImg4 from "../assets/Images/DataEngineering/card/4.svg";
import hoverCardImg5 from "../assets/Images/DataEngineering/card/5.svg";
import hoverCardImg6 from "../assets/Images/DataEngineering/card/6.svg";

import HeroSectionSlide from "../components/HeroSectionSlide";

import HeroImageSlide from "../assets/Images/DataEngineering/data-engineering-banner.svg";

import TechImage1 from "../assets/Images/DataEngineering/tech/informatica.png";
import TechImage2 from "../assets/Images/DataEngineering/tech/azure-data-factory.png";
import TechImage3 from "../assets/Images/DataEngineering/tech/snowflake.png";
import TechImage4 from "../assets/Images/DataEngineering/tech/apache-spark.png";
import TechImage5 from "../assets/Images/DataEngineering/tech/glue.png";
import TechImage6 from "../assets/Images/DataEngineering/tech/big-query.png";
import TechImage7 from "../assets/Images/DataEngineering/tech/kafka.png";
import TechImage8 from "../assets/Images/DataEngineering/tech/databricks.png";
import TechImage9 from "../assets/Images/DataEngineering/tech/oracle.png";
import TechImage10 from "../assets/Images/DataEngineering/tech/ibm-infosphere.png";
import TechImage11 from "../assets/Images/DataEngineering/tech/snaplogic.png";

import CompanyImage1 from "../assets/Images/Finance/Companies/barclays.png";
import CompanyImage2 from "../assets/Images/Finance/Companies/hsbc.png";
import CompanyImage3 from "../assets/Images/Finance/Companies/lloyds.png";
import CompanyImage4 from "../assets/Images/Finance/Companies/netwest.png";
import CompanyImage5 from "../assets/Images/Finance/Companies/sc.png";
import CompanyImage6 from "../assets/Images/Finance/Companies/aviva.png";
import CompanyImage7 from "../assets/Images/Finance/Companies/legal-general.png";
import CompanyImage8 from "../assets/Images/Finance/Companies/prudential.png";
import CompanyImage9 from "../assets/Images/Finance/Companies/saga.png";
import CompanyImage10 from "../assets/Images/Finance/Companies/hl.png";

import AccreditedSection from "../components/AccreditedSection";

import MiniCardWithBgImg from "../components/MiniCardWithBgImg";
import modelsCardImg1 from "../assets/Images/DataEngineering/models/1.svg";
import modelsCardImg2 from "../assets/Images/DataEngineering/models/2.svg";
import modelsCardImg3 from "../assets/Images/DataEngineering/models/3.svg";
import modelsCardImg4 from "../assets/Images/DataEngineering/models/4.svg";
import modelsCardImg5 from "../assets/Images/DataEngineering/models/5.svg";
import RevealAnimation from "../components/RevealAnimation";
import OurModelSection from "../components/OurModelSection";
import DataService from "../services/api/data_services";
import Megaview from "../templates/MegaView";
import Miniview from "../templates/MiniView";

import RightArrow from "../assets/Icons/go.svg";
import Button from "../components/Button";
import VerticalLinearStepper from "../templates/VerticalStepper";

const DataEngineeringPage = () => {
  const [page, setPage] = useState(1);
  const [articleRawData, setArticleRawData] = useState([]);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const listObjectSlideData = [
    {
      title1: "",
      title2: `Equip Your Team With Skilled Data Engineers`,
      sup: "",
      buttonName: "Contact Us",
      buttonUrl: "/contact-us",
      imageUrl: HeroImageSlide,
      items: [
        "Build your elite team of data engineers",
        "Access top 3% data engineering talent",
        "Industry-specific expertise in data engineering",
      ],
      customSlideClass: "customAIArmSlide",
    },
  ];

  const PlainCardData = {
    cardsData: [
      {
        HoverCardWithBtntitle: "Reliable Data Processing",
        HoverCardWithBtnsubtitle:
          "Ensure analysts receive high-quality, accessible data for informed decision-making.",
        HoverCardWithBtnIcon: hoverCardImg1,
        HoverCardWithBtnIconAlt: "Reliable Data Processing",
        HoverCardIconClass: "plain-card-button-main-img",
      },
      {
        HoverCardWithBtntitle: "Overcoming Information Gaps",
        HoverCardWithBtnsubtitle:
          "Eliminate bottlenecks by improving data engineering processes.",
        HoverCardWithBtnIcon: hoverCardImg2,
        HoverCardWithBtnIconAlt: "Overcoming Information Gaps",
        HoverCardIconClass: "plain-card-button-main-img",
      },
      {
        HoverCardWithBtntitle: "Data Integration Solutions",
        HoverCardWithBtnsubtitle:
          "Organise multiple data sources into a cohesive, usable format.",
        HoverCardWithBtnIcon: hoverCardImg3,
        HoverCardWithBtnIconAlt: "Data Integration Solutions",
        HoverCardIconClass: "plain-card-button-main-img",
      },
      {
        HoverCardWithBtntitle: "Modernising Data Infrastructure",
        HoverCardWithBtnsubtitle:
          "Efficiently manage vast data volumes with modern cloud solutions.",
        HoverCardWithBtnIcon: hoverCardImg4,
        HoverCardWithBtnIconAlt: "Modernising Data Infrastructure",
        HoverCardIconClass: "plain-card-button-main-img",
      },
      {
        HoverCardWithBtntitle: "Implementing Data Security",
        HoverCardWithBtnIcon: hoverCardImg5,
        HoverCardWithBtnIconAlt: "Implementing Data Security",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle:
          "Ensure robust security measures for sensitive business and customer data.",
      },
      {
        HoverCardWithBtntitle: "Automating ETL Processes",
        HoverCardWithBtnIcon: hoverCardImg6,
        HoverCardWithBtnIconAlt: "Automating ETL Processes",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle:
          "Streamline operations by automating manual data processes with our engineering solutions.",
      },
    ],
  };

  const ModelsCardData = {
    cardsData: [
      {
        HoverCardWithBtntitle: "Insurance",
        HoverCardWithBtnsubtitle: "",
        HoverCardWithBtnIcon: modelsCardImg1,
        HoverCardWithBtnIconAlt: "Insurance",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardBorder: "card-border-bottom-secondary",
        HoverCardContentList: [
          "Risk assessment modelling",
          "Claims data integration",
          "Actuarial data analytics",
        ],
      },
      {
        HoverCardWithBtntitle: "Financial Services",
        HoverCardWithBtnIcon: modelsCardImg2,
        HoverCardWithBtnIconAlt: "Financial Services",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "",
        HoverCardBorder: "card-border-bottom-primary",
        HoverCardContentList: [
          "High-frequency trading analytics",
          "Real-time transaction processing",
          "Compliance data management",
        ],
      },
      {
        HoverCardWithBtntitle: "Healthcare",
        HoverCardWithBtnIcon: modelsCardImg3,
        HoverCardWithBtnIconAlt: "Healthcare",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "",
        HoverCardBorder: "card-border-bottom-secondary",
        HoverCardContentList: [
          "Electronic health record integration",
          "Medical imaging data management",
          "Predictive health analytics",
        ],
      },
      {
        HoverCardWithBtntitle: "Retail & E-Commerce",
        HoverCardWithBtnIcon: modelsCardImg4,
        HoverCardWithBtnIconAlt: "Retail & E-Commerce",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "",
        HoverCardBorder: "card-border-bottom-primary",
        HoverCardContentList: [
          "Customer behaviour analysis",
          "Inventory optimization",
          "Personalized marketing algorithms",
        ],
      },
      {
        HoverCardWithBtntitle: "Other Business Models",
        HoverCardWithBtnIcon: modelsCardImg5,
        HoverCardWithBtnIconAlt: "Other Business Models",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "",
        HoverCardBorder: "card-border-bottom-secondary",
        HoverCardContentList: [
          "Operational efficiency improvements",
          "Customer segmentation analysis",
          "Predictive maintenance solutions",
        ],
      },
    ],
    lastCardData: {
      title: "We’d love to help with your data",
      subTitle: "",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };
  useEffect(() => {
    DataService.getData(
      "/admin/api/post?limit=1&page=1&page_id=1&type=White Paper"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);

  useEffect(() => {
    DataService.getData(
      `/admin/api/post?limit=3&page=${page}&type=Article&component=mini_view`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const stepper1 = [
    "End-to-end data pipelines",
    "Data Scraping",
    "Data Warehouse",
    "Transaction Database",
    "Data Lake",
    "Batch Processing",
  ];
  const stepper2 = [
    "Stream Processing",
    "Big Data",
    "Data Optimisation",
    "Data Automation",
    "ETL(Extract, Transform, Load)",
    "Microservices oriented architectures",
  ];
  const stepper3 = [
    "Real-time decision-making",
    "Cloud Solutions",
    "Data Governance",
    "Data Management",
  ];
  const stepper4 = [
    "Data Integration",
    "Data Transformation",
    "Data Orchestration",
    "Data Quality Management",
    "Metadata Management",
  ];
  return (
    <Layout
      seo={{
        title: "Data Engineering | Digiryte",
        ogtitle: "Data Engineering | Digiryte",
        description:
          "Digiryte offers comprehensive data engineering solutions, helping businesses unlock the full potential of their data through efficient and scalable data pipelines and infrastructure.",
        keywords:
          "data engineering, data pipelines, data infrastructure, scalable data solutions, Digiryte",
      }}
    >
      <HeroSectionSlide
        mainWrapClass="hideAfterBg"
        listObjectData={listObjectSlideData}
        hideController={true}
        customTitleClass=""
        imageContainerClass="bottom-50"
      />
      <div className="common-bg-light">
        <div className="body-container">
          <RevealAnimation component={"span"} className="title-header">
            Enhance Your Data Strategy with <br />{" "}
            <span className="red-line3letter">Digi</span>ryte’s Data Engineering
            Expertise
          </RevealAnimation>
        </div>
        <div className="body-container">
          <Grid className="mt-50" container direction="row" spacing={3}>
            {PlainCardData.cardsData.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <Card className="appsgridshadow">
                  <PlainCard
                    HoverCardWithBtnCustomClass={"custom-card-wrap"}
                    HoverCardWithBtntitle={item.HoverCardWithBtntitle}
                    HoverCardWithBtnIcon={item.HoverCardWithBtnIcon}
                    HoverCardWithBtnIconAlt={item.HoverCardWithBtnIconAlt}
                    HoverCardIconClass={item.HoverCardIconClass}
                    HoverCardWithBtnsubtitle={item.HoverCardWithBtnsubtitle}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>

      <div className="mt-70">
        <div className="body-container">
          <RevealAnimation component={"span"} className="title-header">
            Data Engineers Across Diverse <br />{" "}
            <span className="red-line3letter">Busi</span>ness Models
          </RevealAnimation>
        </div>
        <div className="body-container">
          <Grid
            container
            direction="row"
            spacing={3}
            className="mt-50 grid-wrap-with-list-item"
          >
            {ModelsCardData.cardsData.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <Card className="appsgridshadow">
                  <PlainCard
                    HoverCardWithBtnCustomClass={`custom-card-wrap custom-card-icons ${item.HoverCardBorder}`}
                    HoverCardWithBtntitle={item.HoverCardWithBtntitle}
                    HoverCardWithBtnIcon={item.HoverCardWithBtnIcon}
                    HoverCardWithBtnIconAlt={item.HoverCardWithBtnIconAlt}
                    HoverCardIconClass={item.HoverCardIconClass}
                    HoverCardWithBtnsubtitle={item.HoverCardWithBtnsubtitle}
                    HoverCardContentList={item.HoverCardContentList}
                  />
                </Card>
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={4} className="card crd-spc">
              <Card className="appsgridshadow mini-card-bg-hght1">
                <MiniCardWithBgImg
                  MiniCardBgImg={ModelsCardData.lastCardData.image}
                  MiniCardBgImgId="100"
                  tip="Get In Touch"
                  tooltip="yes"
                  title={ModelsCardData.lastCardData.title}
                  subtitle={ModelsCardData.lastCardData.subTitle}
                  variant={ModelsCardData.lastCardData.variant}
                  customClassName="three-mini-card-btn mt-70"
                  cardCustomClass={ModelsCardData.lastCardData.customClass}
                  label={ModelsCardData.lastCardData.label}
                  route={ModelsCardData.lastCardData.route}
                />
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="common-o-bg mt-70">
        <CtaCardSection
          title={
            <>
              <span className="animationTitle">Contact Us </span>
              to build your <br />
              success story
            </>
          }
          // titleClassName="customTitle"
          imageUrl={CTAIMAGE2}
          imgClassName="custom-cta-img custom-cta-darken"
        />
      </div>
      <div className="body-container mt-70">
        <RevealAnimation component={"span"} className="title-header">
          We Build Your Data Engineering <br />{" "}
          <span className="red-line3letter">Dre</span>am Team to Deliver
        </RevealAnimation>
        <RevealAnimation className="mt-50 info-view-content">
          Enhance your in-house capabilities with our expert team of offshore
          data engineers. We'll build your dedicated data engineering team to
          create robust data pipelines and advanced analytics solutions,
          optimising processes, generating insights, and fostering innovation
          across your organisation. Below are some of the capabilities these
          dedicated team can deliver:
        </RevealAnimation>
        <Grid container className="mt-50" direction="row" spacing={3}>
          <Grid item md={6}>
            <h3 className="h3-35-header ttc">
              <span className="red-line3letter">Dat</span>a Engineering
              Fundamentals
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper1} />
          </Grid>
          <Grid item md={6}>
            <h3 className="h3-35-header ttc">
              <span className="red-line3letter">Dat</span>a Integration and
              Transformation
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper4} />
          </Grid>
        </Grid>
        <Grid container className="mt-50" direction="row" spacing={3}>
          <Grid item md={6}>
            <h3 className="h3-35-header ttc">
              <span className="red-line3letter">Adv</span>anced Data Processing
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper2} />
          </Grid>

          <Grid item md={6}>
            <h3 className="h3-35-header ttc">
              <span className="red-line3letter">Rea</span>l-time Data Solutions
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper3} />
          </Grid>
        </Grid>
      </div>
      <div className="">
        <TrustedPartner
          title={
            <>
              Unlock Your Data Potential with <br /> Engineers Proficient in
              These <br /> Technologies
            </>
          }
          images={[
            TechImage1,
            TechImage2,
            TechImage3,
            TechImage4,
            TechImage5,
            TechImage6,
            TechImage7,
            TechImage8,
            TechImage9,
            TechImage10,
            TechImage11,
          ]}
          wrapClassName="mt-70"
          imgContainerClassName=" max-w-md mx-auto"
          showAndMore
        />
      </div>
      <OurModelSection />
      <div className="common-bg-light mt-70">
        <div className=" body-container">
          <RevealAnimation component={"h2"} className="title-header">
            <span className="red-line3letter red-3letter-mbl">Dat</span>a
            Engineering Insights
          </RevealAnimation>
          <div className="mt-50"></div>
          <Megaview MegaviewData={megaviewData.data} />
        </div>
        {/* )} */}
        {articleData && articleData.length > 0 && (
          <div className="mt-70">
            <Miniview miniviewData={articleData} />
          </div>
        )}
        {articleRawData.has_more && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <div
              className="mt-60"
              role="presentation"
              style={{ display: "inline-block" }}
            >
              <Button
                icon={
                  <img
                    className="effect-btn-svgicon"
                    src={RightArrow}
                    alt="MORE ARTICLES"
                  />
                }
                customClassName="info-view-resize-btn bg-white"
                color="primary"
                variant="outlined"
                onClick={() => {
                  setPage(page + 1);
                }}
                label="Read more"
                tip="Read More"
                tooltip="no"
              />
            </div>
          </div>
        )}
      </div>
      <AccreditedSection wrapClassName=" bg-white" />
      <TrustedPartner
        title={
          <>
            Leading BFSI Companies <br /> Embracing Modern Technologies <br />{" "}
            Offshore: When Will You?
          </>
        }
        imgContainerClassName=" max-w-lg mx-auto"
        wrapClassName=" common-bg-light"
        images={[
          CompanyImage1,
          CompanyImage2,
          CompanyImage3,
          CompanyImage4,
          CompanyImage5,
          CompanyImage6,
          CompanyImage7,
          CompanyImage8,
          CompanyImage9,
          CompanyImage10,
        ]}
      />
      <div className="mt-70">
        <div className="body-container">
          {/* <!-- Calendly inline widget begin --> */}
          <InlineWidget url="https://calendly.com/harry-digiryte/discoverycall" />
          {/* <!-- Calendly inline widget end --> */}
        </div>
      </div>

      <div className="common-bg-light mt-70">
        <FormSection
          subTitle="From Data Integration to Insights"
          title="Build and Scale Your Data Infrastructure"
        />
      </div>
      <LPFooter />
    </Layout>
  );
};

export default DataEngineeringPage;
